<template>
  <div class="why-us">
    <div class="container container--no-pad">
      <h2 class="text-center title-h2">{{ title }}</h2>
      <div class="subtitle text-center">{{ subtitle }}</div>
      <div class="main-img-wrap position-relative text-md-center no_mobile">
        <span class="flex grid-cols-3 grid gap-y-20 justify-between" >
          <WpImage :key="image?.node?.sourceUrl" :img="image?.node" v-if="false" :set-webp="true"/>
          <div class="mat-row mat-row--right justify-content-center flex " v-for="item in right">
            <div class="mat-row__inner d-flex position-relative align-items-center">
              <div class="icon col-auto">
                <WpImage :key="item?.image?.node?.sourceUrl" :img="item.image.node" :set-webp="true"/>
              </div>
              <div class="mat-info col-auto px-2">
                <div class="mat-info__title">{{ item.title }}</div>
                <div class="mat-info__text" v-html="item.text"></div>
              </div>
            </div>
          </div>

          <div class="mat-row mat-row--left justify-content-center flex " v-for="item in left">
            <div class="mat-row__inner d-flex position-relative align-items-center" >
              <div class="icon col-auto px-2">
                <WpImage :img="item.image.node" :set-webp="true"/>
              </div>
              <div class="mat-info col-auto">
                <div class="mat-info__title">{{ item.title }}</div>
                <div class="mat-info__text" v-html="item.text"></div>
              </div>
            </div>
          </div>
        </span>
      </div>
      <div
          class="main-img-wrap main-img-bg container mobile_only position-relative overflow-hidden"
          data-sticky-container
          ref="mobileContainer"
      >
<!--        <van-sticky offset-top="70" v-if="false">-->

<!--          <nuxt-img id="mobileWhyUs" class="sticky-mat" v-if="false" :src="imageMobile?.node.sourceUrl+'.webp'"-->
<!--                    :alt="imageMobile?.node?.altText"/>-->
<!--        </van-sticky>-->
        <span class="position-relative  col-md-5 col-12 px-2 ">
          <div
              class="mat-row text-right"
              data-aos-offset="150"
              data-aos="zoom-in-left"
              data-aos-delay="0.5"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
              v-for="(item, index) in right"
              :key="index"
          >
            <div class="mat-row__inner d-flex position-relative  ">
              <div class="icon col-auto pl-3">
                <WpImage :img="item.image?.node" :set-webp="true"/>
              </div>
              <div class="mat-info col-7 px-2">
                <div class="mat-info__title">{{ item.title }}</div>
                <div class="mat-info__text" v-html="item.text"></div>
              </div>
            </div>
          </div>
          <div
              class="mat-row text-right"
              data-aos-offset="150"
              data-aos="zoom-in-left"
              data-aos-delay="0.5"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
              v-for="(item, index) in left"
              :key="index"
          >
            <div class="mat-row__inner d-flex position-relative  ">
              <div class="icon col-auto pl-3">
                <WpImage :img="item.image.node" :set-webp="true"/>
              </div>
              <div class="mat-info col-7">
                <div class="mat-info__title">{{ item.title }}</div>
                <div class="mat-info__text" v-html="item.text"></div>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>
<script setup>
import Sticky from "sticky-js";

const {currentPage} = useGlobalStore();
const {title, subtitle, image, imageMobile, left, right} = currentPage?.template?.homepage?.whyUs;
let sticky;
const {$anime} = useNuxtApp();
const isMobile = ref(true);
const mobileContainer = ref(null);
onUpdated(() => {
  // sticky.update();
});
onMounted(() => {
  if (process.client && window) {
    isMobile.value = window.innerWidth < 800;
  }
  // sticky = new Sticky("#mobileWhyUs", {
  //   wrap: false,
  //   marginTop: 120
  // });
  if (process.client && window) {
    // document.addEventListener("scroll", () => {
    //   sticky.update();
    // });
  }
  if (!isMobile.value && false) {

    $anime({
      targets: ".mat-row:nth-of-type(1)",
      right: !isMobile.value ? "-2%" : "5%",
      duration: 1000,
      delay: 500,
      easing: "easeInOutQuad",
    });
    $anime({
      targets: ".mat-row:nth-of-type(2)",
      right: !isMobile.value ? "0%" : "5%",
      duration: 1000,
      delay: 1000,
      easing: "easeInOutQuad",
    });
    $anime({
      targets: ".mat-row:nth-of-type(3)",
      right: !isMobile.value ? "5%" : "5%",
      duration: 1000,
      delay: 1500,
      easing: "easeInOutQuad",
    });
    $anime({
      targets: ".mat-row:nth-of-type(4)",
      left: !isMobile.value ? "0%" : "5%",
      duration: 1000,
      delay: 500,
      easing: "easeInOutQuad",
    });
    $anime({
      targets: ".mat-row:nth-of-type(5)",
      left: "5%",
      duration: 1000,
      delay: 1000,
      easing: "easeInOutQuad",
    });
    $anime({
      targets: ".mat-row:nth-of-type(6)",
      left: !isMobile.value ? "-1%" : "5%",
      duration: 1000,
      delay: 1500,
      easing: "easeInOutQuad",

    });
  }
});
</script>
<style lang="scss" scoped>
.main-img-bg {
  min-height: 500px;
}
.mat-row{
  position: relative;
  top: initial;
  width: auto;
  transform: initial;
  right: initial;
  left: initial;
  //&:nth-of-type(3n + 1){
  //  justify-self: start;
  //}
  //&:nth-of-type(3n + 2){
  //  justify-self: center;
  //}
  //&:nth-of-type(3n + 3){
  //  justify-self: end;
  //}
  .icon:before , .icon:after{
    content: initial;
  }
  .icon{
    img{
      transform: initial;
    }
  }

  &--right,
  &--left{
    .mat-info{
      text-align: right;
    }
  }
}
.why-us{
  background: #fff;
  padding: 60px 0 0;
}
.mat-row{
  @media screen and (max-width: 800px) {
    &:nth-of-type(2n+1){
      .mat-row__inner{
        justify-content: flex-end;
      }
    }
  }
}
</style>
